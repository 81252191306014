import i18n from "@/plugins/i18n";
import _ from "lodash";
import moment from "moment";
import authStore from "@/store/auth.module";

/**
 * Method which checks for numeric value
 * prevents entering non numeric values
 */
import { helpers } from "vuelidate/lib/validators";
import constants from "@/constants/constants";

export const pinCodeValidation = helpers.regex(
  "pinCodeRegex",
  constants.newPinCodeRegex
);
export const phoneNoValidation = helpers.regex(
  "phoneNoValidation",
  constants.newMobileRegex
);
export const emailValidation = helpers.regex(
  "emailRegex",
  constants.emailRegex
);
export const passwordValidation = helpers.regex(
  "passwordValidation",
  constants.passwordRegex
);
export const multipleEmailValidation = helpers.regex(
  "multipleEmailRegex",
  constants.multipleEmailRegex
);
export const newPasswordValidation = helpers.regex(
  "newPasswordValidation",
  constants.newPasswordRegex
);
export default {
  showCancelButton: false,
  confirmButtonText: i18n.t("app.genric.close"),
  cancelButtonText: i18n.t("app.genric.cancel"),
  confirmButtonColor: "#ac000c",
  cancelButtonColor: "#aaa",
  buttonsStyling: true,
  customClass: {
    confirmButton: "btn btn-primary px-4 mr-2",
    cancelButton: "btn  px-4 mr-2",
  },
  width: 400,
  padding: "3em",
};

export function multipleErrorMessages(toast, errorMsgs) {
  let errorMessages = Object.values(errorMsgs);
  let errorMessageText = "";
  errorMessages.forEach((msg, index) => {
    if (typeof errorMessages[index] === "string") {
      errorMessageText =
        errorMessageText + "• " + errorMessages[index][0] + "</br>";
    } else {
      errorMessageText = `${errorMessageText}  •  ${errorMessages[index]} </br> `;
    }
  });
  toast.error({
    message: errorMessageText,
  });
}
export function toLocaleStringDate(date) {
  /* date should be in the format "2020-09-29 11:44:08" as in mysql */
  let localDate = moment(date).utc(date).local().format("YYYY/MM/DD HH:mm");
  return localDate;
}

export function roundUpMinutes(date) {
  let m = moment(date);
  let roundUp =
    m.second() || m.millisecond()
      ? m.add(1, "minute").startOf("minute")
      : m.startOf("minute");

  let localDate = moment(roundUp.toString())
    .utc(roundUp.toString())
    .local()
    .format("YYYY/MM/DD HH:mm");
  return localDate;
}

export function getOnlyDate(date) {
  /* date should be in the format "2020-09-29 11:44:08" as in mysql */
  return moment(date).format("YYYY-MM-DD");
}

export function checkComponentPermissionLocalhost(columnName) {
  let logged_in_user_role_permissions = localStorage.getItem("permissions");
  logged_in_user_role_permissions = JSON.parse(logged_in_user_role_permissions);
  return logged_in_user_role_permissions[columnName] != 1;
}
export function isAuthenticated() {
  if (_.isEmpty(authStore.state.user)) {
    return false;
  }
  return true;
}
/**
 * Method which returns values in particular format
 */
export function numberFormat(
  number,
  decimals = 4,
  dec_point = ".",
  thousands_point = ","
) {
  if (number == null || !isFinite(number)) {
    throw new TypeError("number is not valid");
  }

  if (!decimals) {
    let len = number.toString().split(".").length;
    decimals = len > 1 ? len : 0;
  }

  if (!dec_point) {
    dec_point = ".";
  }

  if (!thousands_point) {
    thousands_point = ",";
  }

  number = parseFloat(number).toFixed(decimals);

  number = number.replace(".", dec_point);

  let splitNum = number.split(dec_point);
  splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
  number = splitNum.join(dec_point);

  return number;
}

export function removeComma(numberString) {
  if (typeof numberString === "string" && numberString.indexOf(",") > -1) {
    return numberString.replace(/,/g, "");
  } else {
    return numberString;
  }
}

export function amountFormatWithOutCircle(
  number,
  decimals = 0,
  dec_point = ".",
  thousands_point = ","
) {
  if (number == null || !isFinite(number)) {
    throw new TypeError("number is not valid");
  }
  if (!decimals) {
    let len = number.toString().split(".").length;
    decimals = len > 1 ? len : 0;
  }
  if (!dec_point) {
    dec_point = ".";
  }
  if (!thousands_point) {
    thousands_point = ",";
  }
  number = parseFloat(number).toFixed(decimals);
  number = number.replace(".", dec_point);
  let splitNum = number.split(dec_point);
  splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
  number = splitNum.join(dec_point);
  return number;
}

export function amountFormat(
  number,
  decimals = 0,
  dec_point = ".",
  thousands_point = ","
) {
  if (number == null || !isFinite(number)) {
    throw new TypeError("number is not valid");
  }
  if (!decimals) {
    let len = number.toString().split(".").length;
    decimals = len > 1 ? len : 0;
  }
  if (!dec_point) {
    dec_point = ".";
  }
  if (!thousands_point) {
    thousands_point = ",";
  }
  number = parseFloat(number).toFixed(decimals);
  number = number.replace(".", dec_point);
  let splitNum = number.split(dec_point);
  splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
  number = splitNum.join(dec_point);
  return number + " 円";
}
export function onlyNumberWithOutDot($event) {
  let keyCode = $event.keyCode ? $event.keyCode : $event.which;
  if (keyCode < 48 || keyCode > 57) {
    $event.preventDefault();
  }
}

export const senitize = helpers.regex(
  "senitize",
  constants.senitize
);

export const allowAddress = helpers.regex(
  "senitize",
  constants.allowAddress
);

export const senitizeNcModel = helpers.regex(
  "senitize",
  constants.senitizeNcModel
);

export const senitizeMtbName = helpers.regex(
  "senitize",
  constants.senitizeMtbName
);

export const sanitizedUrl = helpers.regex(
  "senitize",
  constants.sanitizedUrl
);

export const sanitizedDescription = helpers.regex(
  "senitize",
  constants.sanitizedDescription
);

export const BaseUrl = {
  baseUrl: process.env.VUE_APP_API_URL
};





