/* eslint-disable no-useless-escape */
export default {
  /**
   * Date picker calender weeks jp strings array
   */
  dayStr: ["日", "月", "火", "水", "木", "金", "土"],

  /**Date picker format */
  dateformat: "YYYY/MM/DD",
  datePlceHolder: "YYYY/MM/DD",
  /**Date Range picker place holder */
  rangePlaceHolder: "YYYY/MM/DD ~ YYYY/MM/DD",
  /**Date Time picker format  */
  placeholderFormat: "YYYY/MM/DD HH:MM",
  formatForDateTime: "YYYY/MM/DD hh:mm",
  /**Time picker format */
  timeFormat: "HH:MM",
  timeformat: "HH:mm",
  /**Month picker format*/
  monthFormat: "YYYY/MM",
  telephoneMaxLength: 11,

  maxFileSize: 3000,
  emailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  passwordRegex: /^[A-Za-z0-9\d]{6,}$/,
  pinCodeRegex: /^\d{3}-{0,1}\d{4}$/,
  teliphoneRex: /^(0{1}\d{9,10})$/,
  newPasswordRegex:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/,
  // multipleEmailRegex:
  //   /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
  multipleEmailRegex:/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/,
  newMobileRegex: /^[0-9]{0,12}$/,
  newPinCodeRegex: /^[0-9]\d{2,9}$/,
  optionCountries: [
    { value: "", text: "Select Country" },
    { value: "0", text: "India" },
    { value: "1", text: "Sri Lanka" },
    { value: "2", text: "Bangladesh" },
  ],
  countryCodeList: [
    { value: "", text: "Select Country Code" },
    { value: "+91", text: "+91" },
    { value: "+94", text: "+94" },
    { value: "+880", text: "+880" },
  ],
  optionUserItems: [
    { value: "", text: "Select User" },
    { value: 1, text: "Include Spam User" },
    { value: 2, text: "Show Approval Pending User" },
  ],
  senitize: /^[a-zA-Z0-9 ]+$/,
  senitizeNcModel: /^[a-zA-Z0-9 -]+$/,
  allowAddress: /^[a-zA-Z0-9 #,:\-]+$/,
  senitizeMtbName: /^[a-zA-Z0-9&\/\-_ ]+$/,
  sanitizedUrl: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
  sanitizedDescription:/^[a-zA-Z0-9 ,.\-]+$/
};
